import * as React from 'react'
import { useEffect} from 'react'
import { navigate } from 'gatsby';
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import HomeContent from '../../components/home_content'

/*const UkPage = () => {
  return (
    <Layout isUK={true}>
      <Seo
        title='Roadster | The #1 Commerce Platform for Car Buying'
        excludeTitleSuffix={true}
        description='Want to offer customers the ability to buy cars online? Roadster’s Express Storefront offers digital retailing capabilities — and so much more.'
      />
      <HomeContent isUK={true} />
    </Layout>
  )
}

export default UkPage*/

const Redirect = () => { 
  useEffect(() => {
    navigate('/');
  }, []);
  return null;
};

export default Redirect;
